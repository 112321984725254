:root {
  /* primary colors */
  --purple: rgb(89, 40, 229);
  --pink: rgb(240, 169, 255);
  --black: rgb(34, 34, 34);
  --white: rgb(255, 255, 255);

  /* secondary colors */
  --half-purple: rgba(89, 40, 229, 0.5);
  --card-gray: rgb(244, 244, 244);

  /* fonts and weights */
  --main-font: 'Montserrat', sans-serif;
  --copy-font: 'Open Sans', sans-serif;
  --fw-medium: 600;
  --fw-bold: 700;
}

/* SCROLLBAR STYLES */
/* width */
::-webkit-scrollbar {
  width: 0.75rem;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--card-gray);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--purple);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--purple); 
}

/* SMOOTH SCROLLING */
html {
  scroll-behavior: smooth;
}

/* RESET */
*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--main-font);
  color: var(--black);
}

/* TYPOGRAPHY  */
a {
  display: inline-block;
  font-family: var(--copy-font);
  text-decoration: none;
  transition: all 150ms ease-in;
  cursor: pointer;
}

/* LISTS */
ul {
  list-style: none;
}

li {
  display: inline-block;
}